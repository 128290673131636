<template>
  <div class="info" id="watermark">
    <top-bar :title="'场所详情'" :left="true"></top-bar>

    <van-popup v-model="typeShow" position="bottom" >
      <van-cascader
        :field-names="fieldNames"
        v-model="cascaderValue"
        title="请选择"
        :options="list.placeTypeList"
        @close="typeShow = false"
        @change="typeConfirm"
      />
    </van-popup>

    <van-popup
      v-model="houseTypeShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.placeHouseTypeList"
        value-key="label"
        @confirm="houseTypeConfirm"
        @cancel="houseTypeShow = false"
      />
    </van-popup>

    <van-popup
      v-model="importantShow"
      position="bottom">
      <van-picker
        v-model="importantShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.isList"
        @cancel="importantShow = false"
        @confirm="importantConfirm"
      />
    </van-popup>

    <van-popup
      v-model="importantTypeShow"
      position="bottom">
      <van-picker
        v-model="importantTypeShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.importantTypeList"
        @cancel="importantTypeShow = false"
        @confirm="importantTypeConfirm"
      />
    </van-popup>

    <van-popup
      v-model="trinityShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.isList"
        value-key="label"
        @confirm="trinityConfirm"
        @cancel="trinityShow = false"
      />
    </van-popup>

    <van-popup
      v-model="categoryShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.categoryList"
        value-key="label"
        @confirm="categoryConfirm"
        @cancel="categoryShow = false"
      />
    </van-popup>

    <!-- 所在社区 -->
    <van-popup v-model="communityShow" position="bottom" >
      <van-picker title="请选择" show-toolbar :columns="list.communityList" value-key="label"
                  @confirm="communityConfirm" @cancel="communityShow = false"/>
    </van-popup>

    <van-popup
      v-model="subareaShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.orgList"
        value-key="label"
        @confirm="subareaConfirm"
        @cancel="subareayShow = false"
      />
    </van-popup>

    <van-popup
      v-model="buildingShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.publicBuildingList"
        value-key="label"
        @confirm="buildingConfirm"
        @cancel="buildingShow = false"
      />
    </van-popup>

    <van-popup
      v-model="hasLicenseShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.isList"
        value-key="label"
        @confirm="hasLicenseConfirm"
        @cancel="hasLicenseShow = false"
      />
    </van-popup>

    <van-popup
      v-model="safetyPerilShow"
      position="bottom">
      <van-picker
        v-model="safetyPerilShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.safetyPerilList"
        @cancel="safetyPerilShow = false"
        @confirm="safetyPerilConfirm"
      />
    </van-popup>

    <van-popup
      v-model="scaleShow"
      position="bottom">
      <van-picker
        v-model="scaleShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.isList"
        @cancel="scaleShow = false"
        @confirm="scaleConfirm"
      />
    </van-popup>

    <van-popup
      v-model="hazardousShow"
      position="bottom">
      <van-picker
        v-model="hazardousShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.isList"
        @cancel="hazardousShow = false"
        @confirm="hazardousConfirm"
      />
    </van-popup>

    <div v-show="!gridShow">
      <van-cell-group>
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span>基本信息</span>
          </template>
        </van-cell>
        <van-cell title="所属机构" :value="selectMsg.communityStr" @click="disable ? (communityShow = false) : (communityShow = true)"
                  :value-class="{'value-common':selectMsg.communityStr=='请选择'}" :is-link="!disable" />
        <van-cell title="所属小区" v-if="!streetShow" :value="selectMsg.subareaStr" @click="disable ? (subareaShow = false) : (subareaShow = true)"
                  :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="所属楼幢" v-if="!streetShow" :value="selectMsg.buildingStr" @click="disable ? (buildingShow = false) : (buildingShow = true)"
                  :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="场所名称" required>
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.name" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="是否有营业执照" :value="selectMsg.hasLicenseStr" @click="disable ? (hasLicenseShow = false) : (hasLicenseShow = true)"
                  :value-class="{'value-common':selectMsg.hasLicenseStr=='请选择'}" :is-link="!disable"/>
        <template v-if="dataForm.hasLicense==1">
          <van-cell title="营业执照名称">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.licenseName" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="营业执照号" required>
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.licenseCode" placeholder="请输入" style="text-align: right"/>
          </van-cell>
        </template>

        <van-cell title="经营类型" required :value="selectMsg.placeTypeStr" @click="disable ? (typeShow = false) : (typeShow = true)"
                  :value-class="{'value-common':selectMsg.placeTypeStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="场所类别" required :value="selectMsg.categoryStr" @click="disable ? (categoryShow = false) : (categoryShow = true)"
                  :value-class="{'value-common':selectMsg.categoryStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="场所房屋类型" required :value="selectMsg.houseTypeStr" @click="disable ? (houseTypeShow = false) : (houseTypeShow = true)"
                  :value-class="{'value-common':selectMsg.houseTypeStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="面积(/m²)">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.areas" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="是否重要场所" :value="selectMsg.importantStr" @click="disable ? (importantShow = false) : (importantShow = true)"
                  :value-class="{'value-common':selectMsg.importantStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="重点属性" :value="selectMsg.importantTypeStr" @click="disable ? (importantTypeShow = false) : (importantTypeShow = true)"
                  :value-class="{'value-common':selectMsg.importantTypeStr=='请选择'}" :is-link="!disable"/>
        <van-cell title="详细地址">
          <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.address" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="所属网格" :value="selectMsg.gridName || '请选择'" @click="disable ? (gridShow = false) : (gridShow = true)"
                  :value-class="{'value-common':selectMsg.gridName=='请选择'}" :is-link="!disable" v-if="!disable"/>
      </van-cell-group>

      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span>联络信息</span>
            <div style="color:#387FF5;float:right" @click="firstVisible=!firstVisible"><van-icon :name="firstVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="firstVisible">
          <van-cell title="主要联系人" required>
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.contact" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="联系人电话" required>
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.contactMobile" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="法人/负责人">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.legal" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="法人/负责人电话">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.legalMobile" placeholder="请输入" style="text-align: right"/>
          </van-cell>

          <template v-if="dataForm.category==2">
            <van-cell title="治保负责人姓名">
              <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.safetyDirectorName" placeholder="请输入" style="text-align: right"/>
            </van-cell>
            <van-cell title="治保负责人电话">
              <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.safetyDirectorMobile" placeholder="请输入" style="text-align: right"/>
            </van-cell>
          </template>
        </div>
      </van-cell-group>
      <van-cell-group class="info-second" v-if="!!dataForm.id && disable && !!dataForm.gridId">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span>网格信息</span>
            <div style="color:#387FF5;float:right" @click="firstVisible=!firstVisible"><van-icon :name="firstVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="firstVisible">
          <van-cell title="所属网格">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="selectMsg.gridName" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="网格员">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="selectMsg.gridUser" placeholder="请输入" style="text-align: right"/>
          </van-cell>
        </div>
      </van-cell-group>
      <van-cell-group class="info-second">
        <van-cell>
          <template #title>
            <div class="blue-box"></div>
            <span>附属信息</span>
            <div style="color:#387FF5;float:right" @click="secondVisible=!secondVisible"><van-icon :name="secondVisible?'arrow-up':'arrow-down'"></van-icon></div>
          </template>
        </van-cell>
        <div v-show="secondVisible">
          <van-cell title="卫生许可证号">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.hygieneCode" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="是否三合一场所" :value="selectMsg.trinityStr" @click="disable ? (trinityShow = false) : (trinityShow = true)"
                    :value-class="{'value-common':selectMsg.trinityStr=='请选择'}" :is-link="!disable"/>
          <van-cell title="主管单位">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.competentUnit" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="安全隐患类型" :value="selectMsg.safetyPerilStr" @click="disable ? (safetyPerilShow = false) : (safetyPerilShow = true)"
                    :value-class="{'value-common':selectMsg.safetyPerilStr=='请选择'}" :is-link="!disable"/>
          <van-cell title="煤气瓶数量">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.gasNum" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="灭火器数量">
            <van-field clearable clear-trigger="always" :readonly="disable" v-model="dataForm.fireNum" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="是否规上企业" :value="selectMsg.scaleStr" @click="disable ? (scaleShow = false) : (scaleShow = true)"
                    :value-class="{'value-common':selectMsg.scaleStr=='请选择'}" :is-link="!disable"/>
          <van-cell title="是否危化企业" :value="selectMsg.hazardousStr" @click="disable ? (hazardousShow = false) : (hazardousShow = true)"
                    :value-class="{'value-common':selectMsg.hazardousStr=='请选择'}" :is-link="!disable"/>
          <van-field clearable clear-trigger="always" :readonly="disable" :border="false" v-model="dataForm.remark" rows="3" autosize type="textarea" placeholder="备注" class="left"/>
          <van-row class="uploadBox">
            <van-col :span="24">
              <van-uploader
                v-model="urls"
                :readonly="disable"
                multiple
                @delete="uploaddel"
                :after-read="uploadRead"
                :deletable="!disable"
                :show-upload="!disable"
              />
            </van-col>
          </van-row>
        </div>
      </van-cell-group>

      <van-row class="btns">
        <van-col :span="24" v-if="!disable">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
        <van-row gutter="10" v-else>
          <van-col :span="12">
            <van-button plain type="danger" size="large" round @click="deleteClick" style="background-color:transparent">删除</van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="info" size="large" round @click="disable=!disable">编辑</van-button>
          </van-col>
        </van-row>
      </van-row>
    </div>
    <grid-select v-show="gridShow" :list="list.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { getbelongGrid, getDictTree, getbelongSubArea, getVirtualDict, getBelongCommunity, getMiniGrid} from "@/utils/common";
import { getImageStream } from "@/utils/index";
import GridSelect from '../userRes/grid-select.vue'
export default {
  components: {
    topBar,
    GridSelect
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    if (this.$route.query.id) {
      (this.disable = true), (this.dataForm.id = this.$route.query.id);
      this.getInfo();
    }
    this.streetOrgId = this.$globalData.userInfo.orgId
    let that = this;
    getBelongCommunity(this.$orgId, -1, (communityList) => {
      that.list.communityList = communityList
      that.list.communityList.unshift({
        label: '本街道',
        value: Number(this.streetOrgId)
      })
    })
    getbelongGrid({ orgId: this.$orgId }, function (e) {
      that.list.gridList = that.list.gridList.concat(e);
    });
    getDictTree({ code: "placeType" }, function (e) {
      that.list.placeTypeList = that.list.placeTypeList.concat(e);
    });
    getVirtualDict('importantType', function (e) {
      that.list.importantTypeList = e
    })
    getVirtualDict('safetyPeril', function (e) {
      that.list.safetyPerilList = e
    })
    getMiniGrid({community:this.$orgId}, function (list) {
      that.list.gridList = list
    })
    that.getPlaceHouseType();
  },
  data() {
    return {
      firstVisible: true,
      secondVisible: true,
      fieldNames: { text: "label", value: "value", children: "children" },
      cascaderValue: [],
      streetOrgId: '',
      disable: false,
      typeShow: false,
      houseTypeShow: false,
      importantShow: false,
      importantTypeShow: false,
      trinityShow: false,
      categoryShow: false,
      streetShow: false,
      communityShow: false,
      subareaShow: false,
      buildingShow: false,
      hasLicenseShow: false,
      safetyPerilShow: false,
      scaleShow: false,
      hazardousShow: false,
      fileList: [],
      dataForm: {
        id: "",
        name: "",
        licenseName: "",
        type: "287",
        houseType: 0,
        contact: "",
        contactMobile: "",
        legal: "",
        legalMobile: "",
        important: "0",
        trinity: "0",
        address: "",
        street: '',
        community: "",
        subarea: "",
        buildingId: "1",
        hasLicense: 0,
        licenseCode: "",
        hygieneCode: "",
        safetyDirectorName: '',
        safetyDirectorMobile: '',
        gasNum: '',
        fireNum: '',
        competentUnit: '',
        remark: "",
        imgUrl: "",
        category: "",
        newFileMessages: [],
        deleteFiles: [],
        importantType: 0,
        safetyPeril: 0,
        isScaleCompany: '0',
        isHazardousGoodsCompany: '0',
        gridId: 0,
        miniGridId: 0
      },
      urls: [],
      selectMsg: {
        placeTypeStr: "请选择",
        houseTypeStr: "未知",
        trinityStr: "否",
        importantStr: "否",
        categoryStr: "请选择",
        communityStr: "请选择",
        subareaStr: "请选择",
        buildingStr: "无",
        hasLicenseStr: "否",
        importantTypeStr: '无',
        safetyPerilStr: '无',
        scaleStr: '否',
        hazardousStr: '否',
        gridName: '请选择',
        gridUser: ''
      },
      list: {
        isList: [
          { value: "0", label: "否" },
          { value: "1", label: "是" },
        ],
        categoryList: [
          { value: "1", label: "场所" },
          { value: "2", label: "企业" },
          { value: "3", label: "个体工商户" },
        ],
        orgList: [],
        gridList: [],
        placeTypeList: [],
        placeHouseTypeList: [],
        publicBuildingList: [],
        importantTypeList: [],
        safetyPerilList: [],
        communityList: []
      },
    };
  },
  methods: {
    getPlaceHouseType() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/dict/virtual/placeHouseType"),
        method: "get",
      }).then(({ data }) => {
        if (data.code == 0) {
          for (let i in data.placeHouseTypes) {
            let placeHouseType = data.placeHouseTypes[i];
            placeHouseType["label"] = placeHouseType.label;
            placeHouseType["value"] = placeHouseType.value + "";
          }
          this.list.placeHouseTypeList = data.placeHouseTypes;
        }
      });
    },
    getInfo() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/place/info/info"),
        method: "post",
        params: this.$http.adornParams({
          id: this.dataForm.id,
        }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            if (data.placeInfo.important == 1) {
              this.selectMsg.importantStr = "是";
            } else {
              this.selectMsg.importantStr = "否";
            }

            if (data.placeInfo.hasLicense == 1) {
              this.selectMsg.hasLicenseStr = "是";
            } else {
              this.selectMsg.hasLicenseStr = "否";
            }

            if (data.placeInfo.isScaleCompany == 1) {
              this.selectMsg.scaleStr = "是";
            } else {
              this.selectMsg.scaleStr = "否";
            }

            if (data.placeInfo.isHazardousGoodsCompany == 1) {
              this.selectMsg.hazardousStr = "是";
            } else {
              this.selectMsg.hazardousStr = "否";
            }

            if (data.placeInfo.trinity && data.dataForm.trinity == 1) {
              this.selectMsg.trinityStr = "是";
            } else {
              this.selectMsg.trinityStr = "否";
            }

            if (data.placeInfo.category == 1) {
              this.selectMsg.categoryStr = "场所";
            } else if (data.placeInfo.category == 2) {
              this.selectMsg.categoryStr = "企业";
            } else if (data.placeInfo.category == 3) {
              this.selectMsg.categoryStr = "个体工商户";
            } else {
              this.selectMsg.categoryStr = "请选择";
            }
            console.log(this.selectMsg);
            this.dataForm = data.placeInfo;
            this.selectMsg.placeTypeStr = data.placeInfo.typeName;
            if(data.placeInfo.community == 0) {
              this.streetShow = true
              this.dataForm.street = data.placeInfo.street
              this.selectMsg.communityStr = data.placeInfo.streetName
            } else {
              this.streetShow = false
              this.selectMsg.communityStr = data.placeInfo.communityName
            }
            this.selectMsg.subareaStr = data.placeInfo.subareaName;
            this.selectMsg.houseTypeStr = data.placeInfo.houseTypeStr;
            this.selectMsg.importantTypeStr = data.placeInfo.importantTypeStr
            this.selectMsg.safetyPerilStr = data.placeInfo.safetyPerilStr
            this.selectMsg.gridName = data.placeInfo.gridName
            this.selectMsg.gridUser = data.placeInfo.gridUser
            this.selectMsg.buildingStr =
              data.placeInfo.buildingName != "无"
                ? data.placeInfo.buildingName + "幢"
                : "无";

            // formData: { path: "files/place/" + app.globalData.userInfo.orgId }
            let u;
            let urls = [];
            //获取图片流
            if (data.placeInfo.imgUrl) {
              let imgUrls = data.placeInfo.imgUrl.split(",");

              for (let i in imgUrls) {
                let u;
                let file = imgUrls[i];
                u = getImageStream(imgUrls[i]);
                let url = {
                  uid: urls.length,
                  status: "done", //uploading上传中，done上传完成，error上传失败
                  url: u,
                  fileType: "image/png",
                  realUrl: file.replace(/\\/g, "/"),
                };
                urls.push(url);
              }
            }
            this.urls = urls;
          }

          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    // 经营类型选择
    typeConfirm({ selectedOptions }) {
      let value = selectedOptions[selectedOptions.length - 1];
      if (value) {
        this.selectMsg.placeTypeStr = value.label;
        this.dataForm.type = value.value;
      }
      if (!value.children || value.children.length == 0) {
        this.typeShow = false;
      }
    },
    // 场所房屋类型选择
    houseTypeConfirm(value) {
      if (value) {
        this.selectMsg.houseTypeStr = value.label;
        this.dataForm.houseType = value.value;
      }
      this.houseTypeShow = false;
    },
    // 是否重要场所选择
    importantConfirm(value) {
      if (value) {
        this.selectMsg.importantStr = value.label;
        this.dataForm.important = value.value;
      }
      this.importantShow = false;
    },
    importantTypeConfirm(value) {
      if (value) {
        this.selectMsg.importantTypeStr = value.label;
        this.dataForm.importantType = value.value;
      }
      this.importantTypeShow = false;
    },
    // 是否三合一场所选择
    trinityConfirm(value) {
      if (value) {
        this.selectMsg.trinityStr = value.label;
        this.dataForm.trinity = value.value;
      }
      this.trinityShow = false;
    },
    // 场所类别选择
    categoryConfirm(value) {
      if (value) {
        this.selectMsg.categoryStr = value.label;
        this.dataForm.category = value.value;
      }
      this.categoryShow = false;
    },
    communityConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = '请选择'
        this.dataForm.subarea = ''
        this.selectMsg.buildingStr = '请选择'
        this.dataForm.buildingId = ''
        let communityOrg = value.value
        if (communityOrg == this.streetOrgId) {
          this.selectMsg.communityStr = value.label
          this.dataForm.street = communityOrg
          this.streetShow = true
        } else {
          this.dataForm.street = ''
          this.streetShow = false
          this.selectMsg.communityStr = value.label
          this.dataForm.community = communityOrg
          getBelongCommunity(communityOrg, 0, (communityList) => {
            this.list.orgList = communityList
          })
        }
      }
      this.communityShow = false
    },
    // 所属小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.buildingStr = '请选择'
        this.dataForm.buildingId = ''
        this.selectMsg.subareaStr = value.label;
        this.dataForm.subarea = value.value;
      }
      this.subareaShow = false;
      this.getPublicBuildingList();
    },
    // 所属楼幢选择
    buildingConfirm(value) {
      if (value) {
        this.selectMsg.buildingStr = value.label;
        this.dataForm.buildingId = value.value;
      }
      this.buildingShow = false;
    },
    // 是否有营业执照
    hasLicenseConfirm(value) {
      if (value) {
        this.selectMsg.hasLicenseStr = value.label;
        this.dataForm.hasLicense = value.value;
      }
      this.hasLicenseShow = false;
    },
    safetyPerilConfirm (value) {
      if (value) {
        this.selectMsg.safetyPerilStr = value.label;
        this.dataForm.safetyPeril = value.value;
      }
      this.safetyPerilShow = false;
    },
    scaleConfirm (value) {
      if (value) {
        this.selectMsg.scaleStr = value.label;
        this.dataForm.isScaleCompany = value.value;
      }
      this.scaleShow = false;
    },
    hazardousConfirm (value) {
      if (value) {
        this.selectMsg.hazardousStr = value.label;
        this.dataForm.isHazardousGoodsCompany = value.value;
      }
      this.hazardousShow = false;
    },
    selectGrid (e) {
      this.dataForm.gridId = 0
      this.dataForm.miniGridId = 0
      const {selectList, labelCondition, depth} = e
      this.selectMsg.gridName = labelCondition[0].label;
      this.dataForm.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
      this.dataForm.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      this.gridShow = false
    },
    // 获取楼幢列表
    getPublicBuildingList(buildingType) {
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/info/list"),
        method: "get",
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: this.dataForm.subarea,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.list.publicBuildingList = data.page.list;

          for (let i in data.page.list) {
            let grid = data.page.list[i];
            grid["label"] = grid.name + "幢";
            grid["value"] = grid.id + "";
          }
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    // 删除图片
    uploaddel(file, detail) {
      //debugger;
      let imgUrls = this.dataForm.imgUrl.split(",");
      imgUrls.splice(detail, 1);
      this.dataForm.imgUrl = imgUrls.join();
    },
    // 上传图片
    uploadRead(file) {
      let that = this;
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", "files/place/temporary");
      this.$http({
        url: this.$http.adornUrl("/wxapp/file/upload"),
        method: "post",
        data: formdata,
      }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
        if (data.code == 0) {
          console.log(this.urls);
          // let uid = that.urls.length + 1;
          // let u = getImageStream(data.fileMessage.relativePath);
          // let url = {
          //   uid: uid,
          //   status: "done",
          //   url: u,
          // };
          // that.urls.push(url);
          that.dataForm.imgUrl = that.dataForm.imgUrl
            ? that.dataForm.imgUrl + "," + data.fileMessage.relativePath
            : data.fileMessage.relativePath;

          // this.dataForm.imgUrl += ','+data.fileMessage.relativePath;

          // this.dataForm.newFileMessages.push(data.fileMessage);
          return true;
        } else {
          that.$toast.fail(data.msg);
        }
      });
      })
    },
    submit() {
      let url = "/wxapp/place/info/save";
      if (this.dataForm.id && this.dataForm.id != 0) {
        url = "/wxapp/place/info/update";
      }
      if (this.dataForm.name === '') {return this.$toast.fail('失败:场所名不能为空');}
      if (this.dataForm.type === '') {return this.$toast.fail('失败:场所类型不能为空');}
      if (this.dataForm.contact === '') {return this.$toast.fail('失败:主要联系人不能为空');}
      if (this.dataForm.contactMobile === '') {return this.$toast.fail('失败:联系人电话不能为空');}
      if (this.dataForm.houseType === '') {return this.$toast.fail('失败:场所房屋类型不能为空');}
      if (this.dataForm.category === '') {return this.$toast.fail('失败:场所类别不能为空');}
      if (this.dataForm.hasLicense == 1) {
        if (this.dataForm.licenseCode === '') {return this.$toast.fail('失败:营业执照不能为空');}
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: this.dataForm,
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success("新增成功");
          this.$router.go(-1);
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    deleteClick() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/place/info/delete"),
        method: "post",
        params: this.$http.adornParams({
          ids: this.dataForm.id,
        }),
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success("删除成功");
          this.$router.go(-1);
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
.info >>> .van-radio-group {
  float: unset !important;
}
</style>
