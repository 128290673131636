<template>
  <div class="label-info">
<!--    <div class="select-label">-->
<!--      <div v-for="item in selectNameList" :key="item.value" class="select-item">{{item.label}} <van-icon name="cross" @click.native="cancelLabel(item.value)" /></div>-->
<!--    </div>-->
    <div class="label-list">
      <van-radio-group v-model="selectList" @change="getSelectNameList">
        <van-cell-group v-for="item in list" :key="item.value">
          <van-cell>
            <template #title>
              <van-radio :name="item.value" icon-size="15px">{{item.label}}</van-radio>
            </template>
            <template #right-icon v-if="item.children">
              <van-icon :name="item.isLeaf?'arrow-up':'arrow-down'" @click.native.stop="item.isLeaf=!item.isLeaf" />
            </template>
          </van-cell>
          <template v-if="item.isLeaf">
            <van-cell v-for="val in item.children" :key="val.value" title-style="padding-left:20px">
              <template #title>
                <van-radio :name="val.value" icon-size="15px">{{val.label}}</van-radio>
              </template>
              <template #right-icon v-if="val.children">
                <van-icon :name="val.isLeaf?'arrow-up':'arrow-down'" @click.native.stop="val.isLeaf=!val.isLeaf" />
              </template>
              <template v-if="val.isLeaf">
                <van-cell v-for="v in val.children" :key="v.value" title-style="padding-left:40px">
                  <template #title>
                    <van-radio :name="v.value" icon-size="15px">{{v.label}}</van-radio>
                  </template>
                </van-cell>
              </template>
            </van-cell>
          </template>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="select-button">
      <van-row gutter="20">
        <van-col :span="8"><van-button color="#ccc" block @click="reset">重置</van-button></van-col>
        <van-col :span="16"><van-button type="info" block @click="comfirm">确认</van-button></van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list', 'isArray'],
  data () {
    return {
      labelCondition: 1,
      selectList: [],
      selectNameList: [],
      depth: 0,
    }
  },
  methods: {
    conditionClick (value) {
      this.labelCondition = value
    },
    reset () {
      this.selectList = []
      this.selectNameList = []
    },
    comfirm () {
      this.$emit('selectLabel', {selectList:this.selectList.toString(), labelCondition:this.selectNameList, depth: this.depth})
    },
    getSelectNameList (names) {
      this.selectNameList = []
      this.getName(this.list, names)
    },
    getName (list, value, fatherName) {
      list.map(val => {
        if (val.value == value) {
          this.depth = val.depth
          this.selectNameList.push({
            label: fatherName ? (fatherName + '(' + (val.label.indexOf('(') > 0 ? val.label.split('(')[1].substring(0, val.label.lastIndexOf(')')) : val.label) + ')') : val.label,
            value: val.value,
            fatherValue: this.isArray ? (val.parameter ? parseInt(val.parameter.split('=')[1]) : undefined) : undefined
          })
        }
        if (val.children) {
          this.getName(val.children, value, val.label)
        }
      })
    },
    cancelLabel (value) {
      this.selectList = this.selectList.filter(item => {
        return item != value
      })
      this.selectNameList = this.selectNameList.filter(item => {
        return item.value != value
      })
    }
  }
}
</script>
<style scoped>
.label-info {
  padding-bottom: 168px;
  z-index: 999;
}
.select-label {
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.select-condition {
  width: 100%;
  margin: 10px;
  border-radius: 10px;
  line-height: 80px;
  color: #387FF5;
  background-color: #fff;
  text-align: center;
}
.select-item {
  margin: 10px;
  line-height: 60px;
  color: #387FF5;
  background-color: #fff;
  padding: 0 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
}
.select-button {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .08);
}
</style>
